import React, { useContext } from "react";
import styles from './TablePanel.module.css'
import MachineTestsDataContext from "../../../context/dataVisualization/machineTestsDataContext";
import classNames from 'classnames';
import MS2N_SPECS from "../../../data/MS2N_specifications";
// import MSK_SPECS from "../../../data/MSK_specifications";

// Tabella riassuntiva dei dati dei movimenti/test caricati
export default function TablePanel() {

    const {machineTestsData} = useContext(MachineTestsDataContext);
    const columnLabels = {
        'maxTorque': 'Max Torque [Nm]',
        'minTorque': 'Min Torque [Nm]',
        'maxTorqueAtConstSpeed': 'Max Torque \n@ Const Speed [Nm]',
        'minTorqueAtConstSpeed': 'Min Torque \n@ Const Speed [Nm]',
		'energyCounterKwh': 'Energy Counter [kWh]',
        'snMotor': 'Serial Number Motor',
        'snDrivePower': 'Serial Number Drive \nPower',
        'snDriveControl': 'Serial Number Drive \nControl',
        'motorTypeNumber': 'Type Motor',
        'driveTypeNumberPower': 'Type Drive \nPower',
        'driveTypeNumberControl': 'Type Drive \nControl',
        'driveTypeNumberFirmware': 'Type Drive \nFW',
    }

    // Metodo che restituisce la classe per una cella
    //Se i valori superano le soglie consentite ne differenzia lo stile per evidenziarli
    const getCellClass = (summaryInfo, value) => {
        if(summaryInfo === 'maxTorque'){
            if(value > MS2N_SPECS['max_torque_gearbox'])
                return classNames(styles.tableCell, styles.errorValue);
            if(value > MS2N_SPECS['max_torque_gearbox'] * 0.95)
                return classNames(styles.tableCell, styles.warningValue);
        }
        if(summaryInfo === 'minTorque'){
            if(value < -MS2N_SPECS['max_torque_gearbox'])
                return classNames(styles.tableCell, styles.errorValue);
            if(value < -MS2N_SPECS['max_torque_gearbox'] * 0.95)
                return classNames(styles.tableCell, styles.warningValue);
        }
        return styles.tableCell;
    };

return (
    <div className={styles.tablePanel} id='data-visualization-table'>
        {machineTestsData != null && machineTestsData.testsData != null && machineTestsData.testsData.length > 0 &&
            <table className={styles.table}>
                <thead className={styles.tableHead}>
                    <tr>
                        <th colSpan="2" className={styles.tableHeadCell}>Machine Test</th>
                        {Object.keys(machineTestsData.testsData[0].data.summary).map(summaryInfo => (  
                            <th key={summaryInfo} rowSpan="2" className={styles.tableHeadCell}>{columnLabels[summaryInfo]}</th>  
                        ))}
                    </tr>
                    <tr>
                        <th className={styles.tableHeadCell}>Machine Id</th>
                        <th className={styles.tableHeadCell}>Test Id</th>
                    </tr>
                </thead>
                <tbody className={styles.tableBody}>
                    {machineTestsData.testsData.map((machineTestData, i) => (  
                        <tr  key={machineTestData.machineId + machineTestData.testId} className={i % 2 === 0 ? styles.evenTableRow: styles.oddTableRow}>
                            <td className={classNames(styles.tableCell, styles.cellNoNewLine)}>{machineTestData.machineId}</td>
                            <td className={classNames(styles.tableCell, styles.cellNoNewLine)}>{machineTestData.testId}</td>
                            {Object.keys(machineTestData.data.summary).map(summaryInfo => (  
                                <td key={machineTestData.machineId + machineTestData.testId + summaryInfo} className={getCellClass(summaryInfo, machineTestData.data.summary[summaryInfo])}>{machineTestData.data.summary[summaryInfo]}</td> 
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        }
        
        {/* Tabella vuota per placeholder */}
        {(machineTestsData == null || machineTestsData.testsData == null || machineTestsData.testsData.length === 0) &&
            <table className={styles.table}>
                <thead className={styles.tableHead}>
                    <tr>
                        <th colSpan="2" className={styles.tableHeadCell}>Machine Test</th>
                        <th rowSpan="2" className={classNames(styles.tableHeadCell, styles.tableHeadEmptyColumn)}>Data</th>
                    </tr>
                    <tr>
                        <th className={styles.tableHeadCell}>Machine Id</th>
                        <th className={styles.tableHeadCell}>Test Id</th>
                    </tr>
                </thead>
                <tbody className={styles.tableBody}>
                    <tr className={styles.tableRow}>  
                        <td colSpan="3" className={classNames(styles.tableCell, styles.tableEmptyCell)}></td> 
                    </tr>
                </tbody>
          </table>
        }
    </div>
);
}